import React from "react"

import SEO from "../components/Seo"

const NotFoundPage = () => (
  <>
    <SEO title="404: Not found" />
    <h1>Diese Seite gibt es nicht!</h1>
  </>
)

export default NotFoundPage
